<template>
  <div class="news-details py-8">
    <newsAppbar />

    <div class="ma-4">
      <ViewingTitle :title="displayName" />
      <DeleteOrRestore
        v-if="hasDeletePermission"
        :model="news"
        name="news"
        redirect="/news"
        @deleted="onDelete"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="news-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <v-divider class="news-details__divider"></v-divider>

            <NewsForm :news="news" :access-all-building="accessBuilding" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View News
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import NewsForm from '@/views/Home/News/components/NewsForm'
import NewsAppbar from '@/views/Home/News/components/NewsAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import bus from '@/store/modules/bus'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import { access } from '@/utils/accessBuilding'

export default {
  name: 'NewsDetails',

  components: {
    ViewingTitle,
    NewsAppbar,
    NewsForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      news: (state) => state.news.newsDetails,
      permissions: (state) => state.auth.permissions,
      buildingList: (state) => state.filter.buildingList,
    }),

    displayName() {
      return this.news ? this.news.title : null
    },

    hasDeletePermission() {
      return (
        validatePermissions([PERMISSION.NEWS_DELETE], this.permissions) &&
        this.accessBuilding
      )
    },

    accessBuilding() {
      return access(this.news, this.buildingList)
    },
  },

  methods: {
    ...mapActions({
      getNewsDetails: 'news/getNewsDetails',
    }),

    ...mapMutations({
      clearNewsDetails: 'news/clearNewsDetails',
      removeNews: 'filter/removeNews',
    }),

    onDelete() {
      bus.$emit('newsRemoved', this.news)
      this.removeNews(this.news)
    },

    async getNews() {
      this.loading = true
      await this.getNewsDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getNews()
  },

  destroyed() {
    this.clearNewsDetails()
  },

  watch: {
    $route() {
      this.getNews()
    },
  },
}
</script>

<style lang="scss" scoped>
.news-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
